/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState, useRef } from "react";
import { useAlert } from "react-alert";
import {
  File,
  Edit,
  Clipboard,
  Repeat,
  ChevronRight,
  Search,
  Trash2,
  Flag as Marked,
  FileText,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Form } from "@unform/web";
import { format } from "date-fns";
import { any, arrayOf, func, shape, string } from "prop-types";
import * as Yup from "yup";

import {
  P2,
  Tooltip,
  Flag,
  ProgressBar,
  Toggle,
  Modal,
  Input,
  Upload,
  DatePicker,
  Checkbox,
} from "..";

import { formatErrorMessage, reduce, useApi } from "~/hooks";
import { error, useMe } from "~/services";
import { colors } from "~/styles";

import { Container, ModalView } from "./styles";

const TableRow = ({ columns, redirect, dataLabels, ...rest }) => {
  const api = useApi();
  const { t } = useTranslation("components");
  const alert = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [loading, onLoading] = useState(false);

  const { user } = useMe();

  const formRef = useRef(null);

  const columnsNumber = useMemo(
    () =>
      columns.reduce(
        (acc, curr) => (curr?.type !== "DATA" ? acc + 1 : acc - 1),
        0
      ),
    [columns]
  );
  const sentenceMaxLength =
    rest.sentenceMaxLength ??
    useMemo(() => 112 / columnsNumber, [columnsNumber]);

  const initialData = useMemo(
    () => columns.find((column) => !!column.payload?.process_id)?.payload,
    [columns]
  );

  const handleSubmit = (data) => {
    Yup.object()
      .shape({
        due_date: Yup.string().required(t("tablerow.modal.alerts.due_date")),
        responsible_name: Yup.string().required(
          t("tablerow.modal.alerts.responsible_name")
        ),
        responsible_email: Yup.string()
          .email(t("tablerow.modal.alerts.responsible_email.0"))
          .required(t("tablerow.modal.alerts.responsible_email.1")),
        description: Yup.string().required(
          t("tablerow.modal.alerts.description")
        ),
      })
      .validate(data, {
        abortEarly: false,
      })
      .then(async () => {
        const { types, process_id, repeatAction, ...restInitialData } =
          initialData;
        const { upload, due_date: dueDate, ...restData } = data;
        const due_date = format(dueDate, "yyyy-MM-dd");
        const formattedData = {
          ...restData,
          due_date,
          ...restInitialData,
          process_id: Number(process_id),
        };

        onLoading(true);
        await api()
          .post("/actionplan", formattedData)
          .then(({ data: res }) => {
            if (upload) {
              const form = new FormData();
              form.append("attachment", upload);
              api()
                .post(`/actionplan/${res.id}/attachment`, form)
                .catch(({ response }) => {
                  alert.error(
                    formatErrorMessage(
                      response,
                      t("tablerow.modal.alerts.upload"),
                      t("tablerow.modal.alerts.expired")
                    )
                  );
                });
            }
            setShowModal(false);
            alert.success(t("tablerow.modal.alerts.success"));
          })
          .catch(({ response }) => {
            alert.error(
              formatErrorMessage(
                response,
                t("tablerow.modal.alerts.error"),
                t("tablerow.modal.alerts.expired")
              )
            );
          });
        onLoading(false);
      })
      .catch((err) => error(err, formRef));
  };
  return (
    <Container
      columnsNumber={columnsNumber}
      hasDataLabels={dataLabels.length > 0}
      {...rest}
    >
      {columns.map(({ type, action, payload, className, style }, index) => {
        switch (type) {
          case "HEAD":
            return (
              <P2
                key={index}
                as="th"
                title={payload}
                style={payload === "" ? { maxWidth: "0" } : null}
              >
                {reduce(payload, sentenceMaxLength)}
              </P2>
            );
          case "HEAD_BUTTONS":
            return <P2 key={index} style={{ width: `${4.6 * payload}rem` }} />;
          case "TEXT":
            return (
              <P2
                key={index}
                as="td"
                title={payload?.title || payload || ""}
                className={className}
                data-label={dataLabels[index]}
              >
                {reduce(payload || "", sentenceMaxLength)}
              </P2>
            );
          case "FLAG":
            return (
              <P2
                key={index}
                as="td"
                title={payload}
                data-label={dataLabels[index]}
                style={{ marginRight: "20px" }}
              >
                <Flag status={reduce(payload, 32)} />
              </P2>
            );
          case "DOCUMENT":
            return (
              <P2
                key={index}
                as="td"
                title={payload || ""}
                data-label={dataLabels[index]}
              >
                {payload || ""}
                {action && <Search className="icon-search" onClick={action} />}
              </P2>
            );
          case "PROGRESS_BAR":
            return (
              <P2 key={index} as="td" data-label={dataLabels[index]}>
                <ProgressBar
                  percentage={Number(payload.percentage)}
                  impact_id={payload.impact_id || 0}
                />
              </P2>
            );
          case "TOGGLE":
            return (
              <P2
                key={index}
                as="td"
                style={style ?? { marginLeft: "30px" }}
                className={`toggle ${payload.action.edit ? "modified" : ""}`}
                data-label={dataLabels[index]}
              >
                <Toggle
                  checked={payload.value}
                  onClick={() => {
                    payload.action.toggle();
                  }}
                  isModified={!!payload.action.edit}
                  disabled={user?.isVisualizador()}
                />
                <span>
                  {payload.value ? (
                    <>
                      <span>{t("tablerow.toggle.confirmed.label")}</span>
                      {payload.action.edit && (
                        <Tooltip
                          label={t("tablerow.toggle.confirmed.tooltip")}
                          width={250}
                        >
                          <Edit onClick={payload.action.edit} />
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{t("tablerow.toggle.discarded.label")}</span>
                      {payload.action.edit && (
                        <Tooltip
                          label={t("tablerow.toggle.discarded.tooltip")}
                          width={250}
                        >
                          <Edit onClick={payload.action.edit} />
                        </Tooltip>
                      )}
                    </>
                  )}
                </span>
              </P2>
            );
          case "LINK":
            return (
              <P2
                key={index}
                as="td"
                data-label={dataLabels[index]}
                className="link"
              >
                <Link to={payload}>
                  <ChevronRight size="1.6rem" />
                </Link>
              </P2>
            );
          case "LINKMEDIA":
            let temp = payload?.split("suspeito: ");
            return temp?.length > 1 ? (
              <P2
                key={index}
                as="td"
                data-label={dataLabels[index]}
                className="link"
              >
                <a href={temp[1]} target="_blank" title={payload}>
                  {reduce(payload || "", sentenceMaxLength)}
                </a>
              </P2>
            ) : (
              <P2
                key={index}
                as="td"
                data-label={dataLabels[index]}
                className="link"
              >
                <a href={payload} target="_blank" title={payload}>
                  {reduce(payload || "", sentenceMaxLength)}
                </a>
              </P2>
            );
          case "BUTTONS":
            return (
              <P2
                key={index}
                as="td"
                length={payload.types.length}
                className={payload.hide && "hide"}
                data-label={dataLabels[index]}
              >
                {payload.types.includes("REPEAT") && (
                  <Tooltip label={t("tablerow.buttons.repeat")} width={180}>
                    <button
                      type="button"
                      aria-label="action"
                      className="repeat"
                      onClick={payload.repeatAction.onClick}
                    >
                      <Repeat size="1.6rem" />
                    </button>
                  </Tooltip>
                )}
                {payload.types.includes("ADD_CLIPBOARD") && (
                  <Tooltip label={t("tablerow.buttons.clipboard")} width={180}>
                    <button
                      disabled={user?.isVisualizador()}
                      type="button"
                      aria-label="action"
                      className="clipboard"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      <Clipboard size="1.6rem" />
                      <span>+</span>
                    </button>
                  </Tooltip>
                )}
                {payload.types.includes("FILE") && (
                  <Tooltip
                    label={t("tablerow.buttons.file")}
                    width={180}
                    className={payload.hide && "hide"}
                  >
                    <button
                      type="button"
                      aria-label="action"
                      className={`file ${payload.hide && "hide"}`}
                      onClick={!payload.hide && payload.fileAction?.onClick}
                    >
                      <File size="1.6rem" />
                    </button>
                  </Tooltip>
                )}
                {payload.types.includes("GENERATE_PDF") && (
                  <Tooltip
                    label={
                      payload.props.pdf.state.disabled
                        ? payload.props.pdf.state.text
                        : t("tablerow.buttons.generate_pdf")
                    }
                    width={payload.props.pdf.state.disabled ? 400 : 180}
                  >
                    <button
                      type="button"
                      aria-label="action"
                      className="generate_pdf"
                      onClick={payload.generatePdfAction.onClick}
                      disabled={payload.props.pdf.state.disabled}
                    >
                      <FileText size="1.6rem" />
                    </button>
                  </Tooltip>
                )}
                {payload.types.includes("REDIRECT") && (
                  <button
                    type="button"
                    aria-label="action"
                    className="redirect"
                    onClick={redirect}
                  >
                    <ChevronRight size="1.6rem" />
                  </button>
                )}
                {payload.types.includes("DELETE") && (
                  <Tooltip
                    label={t("tablerow.buttons.delete")}
                    width={180}
                    className={payload.hide && "hide"}
                  >
                    <button
                      type="button"
                      aria-label="action"
                      className={`delete ${payload.hide && "hide"}`}
                      onClick={!payload.hide && payload.deleteAction?.onClick}
                    >
                      <Trash2 size="1.6rem" />
                    </button>
                  </Tooltip>
                )}
              </P2>
            );
          case "MARKED":
            return (
              <div key={index}>
                <button
                  type="button"
                  aria-label="action"
                  style={{ marginLeft: "65px" }}
                  onClick={payload.action.toggle}
                >
                  {payload.active ? (
                    <Marked size="1.6rem" color={colors.primary._000} />
                  ) : (
                    <Marked size="1.6rem" color="#B6BACD" />
                  )}
                </button>
              </div>
            );
          case "CHECKBOX":
            return (
              <P2 key={index} as="td" style={{ maxWidth: "0" }}>
                <input
                  type="checkbox"
                  style={{ appearance: "auto" }}
                  checked={payload.value.state}
                  onChange={(evt) =>
                    payload.action(payload.value.value, evt.target.checked)
                  }
                />
              </P2>
            );
          case "CHECKALL":
            return (
              <P2 key={index} as="td" style={{ maxWidth: "0" }}>
                <Checkbox
                  size={"sm"}
                  name={payload.value.name || ""}
                  selectedProp={payload.value.state}
                  onSelected={(evt) => {
                    payload.action(payload.value.value, evt.target.checked);
                  }}
                />
              </P2>
            );
          case "TOOLTIP":
            return (
              <Tooltip
                direction={{ side: "up", offset: "-2" }}
                width={150}
                label={payload}
              >
                <P2
                  key={index}
                  as="td"
                  title={payload?.title || payload || ""}
                  className={className}
                  data-label={dataLabels[index]}
                >
                  {reduce(payload || "", sentenceMaxLength)}
                </P2>
              </Tooltip>
            );
          default:
            break;
        }
        return null;
      })}

      {showModal && (
        <Modal
          className="action-plan-modal"
          title={t("tablerow.modal.title")}
          show
          onShow={setShowModal}
          action={{
            label: t("tablerow.modal.action"),
            onClick: () => formRef?.current?.submitForm(),
            loading,
          }}
        >
          <ModalView>
            <P2>{t("tablerow.modal.text")}</P2>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={initialData}
            >
              <DatePicker
                name="due_date"
                label={t("tablerow.modal.datepicker.label")}
                minDate={new Date()}
                appearance="secondary"
                placeholder={t("tablerow.modal.datepicker.placeholder")}
              />
              <Input
                name="responsible_name"
                label={t("tablerow.modal.responsible_name.label")}
                appearance="secondary"
                placeholder={t("tablerow.modal.responsible_name.placeholder")}
              />
              <Input
                name="responsible_email"
                label={t("tablerow.modal.responsible_email.label")}
                appearance="secondary"
                placeholder={t("tablerow.modal.responsible_email.placeholder")}
              />
              <Input
                name="description"
                label={t("tablerow.modal.description.label")}
                appearance="secondary"
                placeholder={t("tablerow.modal.description.placeholder")}
                textarea
              />
              <Upload
                name="upload"
                label={t("tablerow.modal.upload.label")}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>
      )}
    </Container>
  );
};

TableRow.propTypes = {
  columns: arrayOf(
    shape({
      // eslint-disable-next-line react/forbid-prop-types
      payload: any.isRequired,
      type: string.isRequired,
    })
  ).isRequired,
  redirect: func,
  dataLabels: arrayOf(string),
};

TableRow.defaultProps = {
  redirect: null,
  dataLabels: [],
};

export default TableRow;
