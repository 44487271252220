import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input, Modal, P2 } from '~/components';
import { error } from '~/services';
import { ModalView } from '~/views/Search/Response/Diligence2/styles';

import { formatErrorMessage, useApi } from '.';
import {useSelectRisks} from "~/views/Search/Response/Diligence2/services/selectRisksContext";

const useToggleColumn = ({ seem, processID, onSucceed }) => {
  const api = useApi();
  const { t } = useTranslation('diligence');
  const { t:tAuth } = useTranslation('auth');
  const { selectedRisks } = useSelectRisks();
  const [loading, setLoading] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [toggleData, setToggleData] = useState(null);
  const [showChangeReason, setShowChangeReason] = useState(false);
  const toggleRef = useRef(null);
  const reasonRef = useRef(null);
  const alert = useAlert();

  useEffect(() => {
    if (! showToggle && ! showChangeReason) {
      setToggleData(null);
    }
  }, [showToggle, showChangeReason, selectedRisks]);

  const handleSubmit = useCallback(async (submitData, reasonOnly) => {
    if (seem?.data?.id) {
      return alert.error(t('toggle.alerts.finalized'));
    }

    try {
      await Yup.object().shape({
        reason: Yup.string().required(t('toggle.alerts.reason')),
      }).validate(submitData, { abortEarly: false });
    } catch (err) {
      return error(err, toggleRef);
    }

    const {
      reason, themeId, riskId,
    } = submitData;

    let requestURL = `/process/${processID}/theme/${themeId}/source/${riskId}`;
    if (reasonOnly) {
      requestURL = `${requestURL}/reason`;
    }

    setLoading(true);
    try {
      await api().post(requestURL, { reason });
      alert.success(
        `${t('toggle.alerts.success_item')} ${t('toggle.alerts.wait')}`,
      );

      setShowToggle(false);
      setShowChangeReason(false);

      if (onSucceed) onSucceed();
    } catch ({ response }) {
      alert.error(
        formatErrorMessage(response, t('toggle.alerts.error_item'),tAuth('expired')),
      );
    } finally {
      setLoading(false);
    }
  }, [seem, t, alert, processID, onSucceed, tAuth, selectedRisks]);

  const toggleModals = (
    <>
      {showToggle && (
        <Modal
          hasAction
          hasOverflow={false}
          show
          title={`${toggleData.active
            ? t('toggle.modal_toggle.title.discard')
            : t('toggle.modal_toggle.title.confirm')
          } ${toggleData.riskId
            ? t('toggle.modal_toggle.title.item')
            : t('toggle.modal_toggle.title.source')
          } ${t('toggle.modal_toggle.title.main')}`}
          onShow={setShowToggle}
          action={{
            label: `${t('toggle.modal_toggle.action.main')} ${toggleData.active
              ? t('toggle.modal_toggle.action.discard')
              : t('toggle.modal_toggle.action.confirm')
            }`,
            loading,
            onClick: () => toggleRef?.current?.submitForm(),
          }}
        >
          <ModalView>
            <P2>
              {`${t('toggle.modal_toggle.text.main')} ${toggleData.active
                ? t('toggle.modal_toggle.text.discard')
                : t('toggle.modal_toggle.text.confirm')
              } ${t('toggle.modal_toggle.text.sub')}`}
            </P2>
            <Form ref={toggleRef} onSubmit={(submitData) => handleSubmit(submitData, false)}>
              <Input name="themeId" reveal={false} value={toggleData.themeId} />
              <Input name="riskId" reveal={false} value={toggleData.riskId} />
              <Input
                name="reason"
                label={t('toggle.modal_toggle.reason.label')}
                placeholder={t('toggle.modal_toggle.reason.placeholder')}
                appearance="secondary"
                textarea
              />
            </Form>
          </ModalView>
        </Modal>
      )}
      {showChangeReason && (
        <Modal
          title={`${t('toggle.modal_edit.title.main')} ${toggleData.active
            ? t('toggle.modal_edit.title.confirm')
            : t('toggle.modal_edit.title.discard')
          }`}
          show
          onShow={setShowChangeReason}
          action={{
            label: t('toggle.modal_edit.action'),
            loading,
            onClick: () => reasonRef?.current?.submitForm(),
          }}
          loading={loading}
        >
          <ModalView>
            <P2>
              {`${t('toggle.modal_edit.text.main')} ${toggleData.active
                ? t('toggle.modal_edit.text.confirm')
                : t('toggle.modal_edit.text.discard')
              }.`}
            </P2>
            <Form
              ref={reasonRef}
              onSubmit={(submitData) => handleSubmit(submitData, true)}
              initialData={{ reason: toggleData.reason }}
            >
              <Input name="themeId" reveal={false} value={toggleData.themeId} />
              <Input name="riskId" reveal={false} value={toggleData.riskId} />
              <Input
                name="reason"
                label={t('toggle.modal_edit.reason.label')}
                placeholder={`${t(
                  'toggle.modal_edit.reason.placeholder.main',
                )} ${toggleData.active
                  ? t('toggle.modal_edit.reason.placeholder.confirm')
                  : t('toggle.modal_edit.reason.placeholder.discard')
                }`}
                appearance="secondary"
                textarea
              />
            </Form>
          </ModalView>
        </Modal>
      )}
    </>
  );

  const generateToggleColumn = (value, themeID, riskSourceID, reason, style = null) => ({
    type: 'TOGGLE',
    payload: {
      value,
      action: {
        toggle () {
          if (seem?.data?.id) {
            return alert.error(t('toggle.alerts.finalized'));
          }

          setToggleData({
            riskId: riskSourceID,
            themeId: themeID,
            active: value,
            reason,
          });

          setShowToggle(true);
        },
        edit: reason ? () => {
          setToggleData({
            riskId: riskSourceID,
            themeId: themeID,
            active: value,
            reason,
          });

          setShowChangeReason(true);
        } : null,
      },
    },
    style: style
  });

  return {
    toggleModals, generateToggleColumn,
  };
};

export default useToggleColumn;
